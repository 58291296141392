import React from "react"
import ReactWOW from "react-wow"
import { Col, Row, Container } from "react-bootstrap"
import { Link } from "gatsby"
import bannerImg from "../../assets/images/banner-img5.svg"
import NetversysSignet from "../../assets/images/Netversys_Weiss_1C_Signet.svg"
import NetstorsysSignet from "../../assets/images/Netstorsys_Weiss_1C_Signet.svg"


const Banner = () => {
  return (
    <div className="it-services-banner">
      <Container>
        <Row className="align-items-center">
          <Col lg={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
            <div className="main-banner-content">
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h1>Software für Versandlogistik und Lagerlogistik</h1>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInLeft">
                <p>Mit NETVERSYS versenden Sie Pakete und Paletten optimiert mit vielen Versendern. NETSTORSYS
                  ist die Software die Ordnung in Ihr Lager bringt.</p>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInRight">
                <div className="btn-box">
                  <Link to="/versandsoftware/" className="default-btn"
                        style={{ width: "220px", textDecoration: "none" }}>
                    <div className={"d-flex"}>
                      <img src={NetversysSignet} alt={"Netversys Signet"} width={"25px"} className={"mr-2"} />
                      <div>NETVERSYS</div>
                    </div>
                  </Link>
                </div>

                <div className="btn-box">
                  <Link to="/lagerverwaltung/" className="default-btn" style={{ width: "220px", textDecoration: "none" }}>
                    <div className={"d-flex"}>
                    <img src={NetstorsysSignet} alt={"Netstorsys Signet"} width={"25px"} className={"mr-2"} />
                    <div>NETSTORSYS</div>
                    </div>
                  </Link>
                </div>
              </ReactWOW>
            </div>
          </Col>

          <Col lg={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
            <ReactWOW delay=".1s" animation="fadeInUp">
              <div className="main-banner-image">
                <img src={bannerImg} alt="banner" />
              </div>
            </ReactWOW>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
